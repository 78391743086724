body,
main {
  height: 100%;
  margin: 25;
  overflow: hidden;
}

.mainT {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: darkorange;
  color: white;
}

.scroll {
  min-height: 80vh;
  overflow-y: auto;
  padding: 16px;
}