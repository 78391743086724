body,
main {
  height: 100%;
  margin: 25;
  overflow: hidden;
}

.homeT {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: darkorange;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
}

h1,
p {
  margin: 10px;
}

button {
  padding: 10px 20px;
  background-color: white;
  color: orange;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #ffd699;
}